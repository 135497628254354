import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Slider from "react-slick";
import '../../assets/css/weblink.css';
import '../../assets/css/style-acces-rapide.css';
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div className="col-md-1 arrowPositionLeft survol" >

        <span
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
              }
            style={{ display: "block" }}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
           <i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i>
        </span>
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

    <div className="col-md-1 arrowPositionRight survol">
        <span
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}

            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i>

        </span>
    </div>
)

const Links = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows:true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        autoplaySpeed: 2000,
        focusOnSelect: true,
        draggable: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <div className="pb-2 mb-3 weblink">
            <h5 className="text-center text-white">LIENS UTILES</h5>

                <Slider {...settings} style={{height: '100px'}}>
                    {defaultdatas && defaultdatas.weblinks.map(link => (
                        <div key={link.id} className="rounded border border-light text-white p-2">
                           <a className="text-white liens" href={link.access_link}>
                        <span className="lien-icon"><i className="fa fa-link"></i></span>
                        {link.name}
                    </a>
                        </div>
                    ))}
                </Slider>
            
            <div>
                <p>
                    <div>
                        <h5 className="text-center text-white title-acces">Accès Rapide</h5>

                    </div>
                    
                    <div className="row acces-rapide">
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dpp.agriculture.gouv.bj/page/organigramme-et-ressources-humaines" className="text-white size-text">Organigramme</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/page/missions-et-attibutions" className="text-white size-text">Mission et attributions</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/page/structures-et-personnes-rattachees" className="text-white size-text">Structures et Personnes Rattachées</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/page/actualites-dpp" className="text-white size-text">Actualités DPP</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/services" className="text-white size-text">Services DPP</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Guide%20technique%20de%20capitalisation%20des%20exp%C3%A9riences" className="text-white size-text">Guide technique de capitalisation des expériences</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Document%20de%20capitalisation" className="text-white size-text">Document de capitalisation</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Politiques" className="text-white size-text">Politiques</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Strat%C3%A9gie" className="text-white size-text">Stratégie</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/projets" className="text-white size-text">Tous les projets</a></li>    
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Suivi%20d'ex%C3%A9cution" className="text-white size-text">Suivi d'exécution</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Suivi%20de%20performance" className="text-white size-text">Suivi de performance</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Outils%20de%20suivi" className="text-white size-text">Outils de suivi</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/section/Contacts/Contacts" className="text-white size-text">Contact</a></li>
                                <li><a href="http://dpp.agriculture.gouv.bj/page/mentions-legales" className="text-white size-text">Mentions Légales</a></li>
                            </ul>
                        </div>
                    </div>

                    
                </p>
            </div>

           



        </div>
    )
}

export default Links
