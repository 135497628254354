import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
const directory = process.env.REACT_APP_UPLOAD_DIRECTORY

const ContentService = ({ services }) => {

    const { appState: { defaultdatas } } = useContext(AppContext)
    const [setting, setSetting] = useState(null)

    useEffect(() => {
        if (defaultdatas) {
            setSetting(defaultdatas.setting)
        }
    }, [defaultdatas])

    return (
        <>
            <section id="page-banner" className="pt-80 pb-40 bg_cover" data-overlay="8">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner-cont">
                            {setting &&<img src={ directory+setting.fichier.filename} 
                                    className=" img-fluid img-thumbnail rounded  mx-auto d-block director-img" alt="Directeur" />}
                                <h3 className="text-center text-white">{setting && setting.director_name}</h3>
                                <h5 className="text-center text-light mt-3">{setting && setting.director_position}
                                </h5>
                                <h6 className="text-center mt-3">
                                    <a href={setting && setting.facebook_url}><i className="fa fa-facebook round-sociale-media"></i></a>
                                    <a href={setting && setting.twitter_url}><i className="fa fa-twitter round-sociale-media"></i></a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="simple-page" className="pt-30 pb-50 bg-light">

                <div className="container ">
                    <div className="row">
                        <div className="col-md-8 section-title page">
                            <h5 className="mb-3">{setting && setting.welcome_title}</h5>
                            <div className="text-align-justify" dangerouslySetInnerHTML={{ __html: setting && setting.welcome_content }}></div>
                        </div>
                        <div className="col-md-4">
                            <ul className="list-group">
                                {
                                    services.map(page => (
                                        <li key={page.id} className="list-group-item"><a href={`/page/${page.permalink}`}><small>{page.title}</small></a></li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ContentService;
