import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import {getResource} from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';
import ContentModuleProjets from '../components/Module/ContentModuleProjets';
import {Alert} from 'react-bootstrap';

export default function ListeProjets() {

    const {slug} = useParams();
    const[currentprojets, setCurrentProjets] = useState(null);

    const [projets, setProjets] = useState([]);
    const [numberPage, setNumberPage] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [documents, setDocuments] = useState([])
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
    const [alert, setAlert] = useState(null);
    const [query, setQuery] = useState("");

    useEffect(() => {
        getResource(`projets?page=${currentPage}&public=1`).then(res => {
            setProjets(res.data.data)
            
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })

    }, [currentPage])

    
    
    return (
        <div>
            <div>
                <Ariane title="Projets"/>
            </div>
            <div id="simple-page" className="pt-90 pb-120 bg-white">
                <div className="container">
                    
                    <div className="row">
                        <div className="col-md-6 offset-md-3 pb-40">
                            {
                                alert && <Alert style={{color:'black', marginTop:'10px'}} variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
                                { alert.message }
                                </Alert>
                            }
                            {/*<div className="input-group mb-3 mt-4">
                                <input type="text" className="form-control newsletter-input" onChange={HandleSubmit} placeholder="Recherche un document"
                                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <a className="input-group-text site-secondary-bgcolor" disabled id="basic-addon2">{button}</a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    <div class="row">
                        {typeof projets !== 'undefined' && projets.length > 0 ? projets.map(data => (
                        <div key={data.id} className="col-sm-12 col-md-6 pt-10">
                            <div className="card">
                                <div className="card-header ">
                                    <h5 className="card-title text-white ">{data.nom}</h5>
                                </div>
                                <div className="card-body projet-body">
                                    <p className="card-text">{data.description&&data.description.slice(0,100)+"..."}
                                    </p>
                                    <a href={`/projets/${data.id}`} className="btn btn-lg btn-success  projet-action"
                                        title="Lire plus"> <i className="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        )): <Alert className="col-md-6" variant="secondary"><h3>Pas de document disponible</h3></Alert>
                        }
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="courses-pagination mt-50 pb-3">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item cursor">
                                        <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                            <i className="fa fa-angle-left"></i>
                                        </a>
                                    </li>
                                    {
                                        numberPage.map((n) => (
                                            <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                        ))
                                    }
                                    <li className="page-item cursor">
                                        <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                            <i className="fa fa-angle-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
