import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import _ from 'lodash'
import { getResource } from '../../service/api'
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const PUBLIC_URL = process.env.PUBLIC_URL

const OtherModuleMenu = () => {

    const [moduleMenu, setModuleMenu] = useState([])

    useEffect(() => {
        getResource('modules').then(res => {
            setModuleMenu(res.data.data);
        })
    }, [])
 

    const parents = moduleMenu.filter( f => f.parents?.name == 'MENU-MODULE');

    const getChildrenByParentId = (e) => {
        return moduleMenu.filter( f => f.id === e);
    }

    return (
    	<div id="extraNav" className="extramenu-drawer-nav">
            <span className="drawer-closebtn">&times;</span>
            <div className="accordion mt-5" id="accordionExample">
                
				{parents.map( (p,x)=> ( 
				p && 
				<div className="card" key={'navlink'+x}>
					<div className="card-header cursor-point accordimus accordss p-0" id={"heading"+x} data-toggle="collapse"
                        data-target={"#collapse"+x} aria-expanded="false" aria-controls={"collapse"+x}>
                        <span className="float-right extramenu-drawer-icon-holder"><i
                                className="accord-icon fa fa-chevron-down"></i></span>
                        <p className="mb-0 text-left font-weight-bold p-2">
                            {p.name}
                        </p>
                    </div>
					
                    <div id={"collapse"+x} className="collapse shadow" aria-labelledby={"heading"+x}
                        data-parent="#accordionExample">
                        <div className="card-body">
                            <div className="row">
                                {p.children?.map( (q,y)=> (
                                q && 
								<div className="col-12">
                                    <div className="list-group" id={"list-tab-"+y} role="tablist">
                                        <a className="list-group-item list-group-item-action" id="list-home-list"
                                            data-toggle="list" href={"#tab_"+y} role="tab"
                                            aria-controls="home"><i className="fa fa-caret-right"></i> 
											{q.name}</a>
											
                                    </div>
                                </div>
								))}
								<div className="col-12">
                                    <div className="tab-content" id={"nav-tabContent"+x}>
										{p.children?.map( (r,z)=> { 
										var curChild = getChildrenByParentId(r.id)[0];
										return r && 
									    <div className="tab-pane fade show active" id={"tab_"+z}
                                            role="tabpanel" aria-labelledby="list-home-list">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <ul className="other-menu-sub">
                                                                {curChild.pages?.map( (s,key)=> (
																s && 
																<li key={'menu-page'+key} className="pt-10 pb-10">
																	<Link to={"/page/"+s.permalink}>
																	<i className="fa fa-dot-circle-o"></i> {"   "}
																	{s.title} 
																	</Link>
																</li>
																))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
										})}
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				))}

			</div>
		</div>
          

)
}
export default OtherModuleMenu;