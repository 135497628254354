import React from 'react';
import { Button } from 'reactstrap';

// import ReactExport from "react-export-excel";
import Pdf from "react-to-pdf";

const ref = React.createRef();
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ContentProjets = ({ projets }) => {
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
    };
    return (
        <div id="simple-page" class="pt-90 pb-120 bg-white justify">
            <div class="container">
                <div class="row" ref={ref}>
                    
                    <div class="col-sm-12 col-md-12 mt-4 pr-1 pl-2">
                        <h5 class="text-upper w-100 mb-10">Fiche Projet
                            {/* <i title="Télécharger le fiche projet"
                            class="fa fa-download float-right"></i> */}
                        </h5>
                        <div class="" >
                            <div class="card-body p-1">
                                <table class="table table-hover table-bordered ficheprojet-table table-striped">
                                    <thead>
                                        <tr class="bg-secondary text-white">
                                            <th >TITRE DU PROJET </th>
                                            <th >{projets.nom}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td class="title">SECTEURS D’ACTIVITE </td>
                                            <td class="value">{projets.secteurs_activite} </td>
                                        </tr>
                                        <tr>
                                            <td class="title">INSTITUTION DE TUTELLE </td>
                                            <td class="value">{projets.institution_de_tutelle}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">PILIER ET AXE D’APPARTENANCE DANS LE PAG 2016 – 2021</td>
                                            <td class="value">
                                                {projets.pilier}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="title">FINANCEMENT </td>
                                            <td class="value">{projets.financement} </td>
                                        </tr>
                                        <tr>
                                            <td class="title">CONTEXTE ET JUSTIFICATION :</td>
                                            <td class="value">
                                                {projets.context_et_justification}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">OBJECTIF GLOBAL (BUT) </td>
                                            <td class="value">{projets.objectif_global}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">OBJECTIFS SPECIFIQUES </td>
                                            <td class="value">{projets.objectifs_specifiques}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">OBJECTIF GLOBAL (BUT) </td>
                                            <td class="value">{projets.objectifs_specifiques}</td>
                                        </tr>
                                        <tr>
                                            <td class="title"> BENEFICIAIRES </td>
                                            <td class="value">{projets.beneficiaires}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">RESULTAT ATTENDUS </td>
                                            <td class="value">{projets.resultats_attendus}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">IMPACTS</td>
                                            <td class="value">{projets.impacts}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">AXE D’APPARTENANCE </td>
                                            <td class="value">{projets.axe_appartenance}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">ACTIONS MISE EN VIGUEUR </td>
                                            <td class="value">{projets.actions_mise_en_vigueur}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">ARRANGEMENT INSTITUTIONNEL DE GESTION</td>
                                            <td class="value">{projets.arrangement_institutionnel_de_gestion}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">ZONES D'INTERVENTION </td>
                                            <td class="value">{projets.zones_intervention}</td>
                                        </tr>
                                        <tr>
                                            <td class="title">ARRIMAGE </td>
                                            <td class="value">{projets.arrimage_odd}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Button color="info" onClick={() => window.print()}>TELECHARGER</Button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 mt-5 pr-2 pl-1 mb-4">
                        <h5 class="text-upper mb-10">Synthèse</h5>
                        <div class="card" style={{backgroundColor:"#d3d3d3"}}>
                            <div class="card-body text-align-justify">
                                <p>
                                    {projets.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Pdf targetRef={ref} filename="projet.pdf" >
                    {({ toPdf }) => <Button color="info" onClick={toPdf}>TELECHARGER</Button>}
                </Pdf> */}
            </div>
        </div>
    );
}
export default ContentProjets;