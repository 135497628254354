import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'


export default function ContentModuleContact({ module }) {

    const [contacts, setContacts] = useState([])

    useEffect(() => {
        getResource(`contacts?module_id=${module.id}&public=1`).then(res => {
            console.log(res.data.data);
            setContacts(res.data.data)
        })
    }, [])
    return (
        <section id="contact-page" className="pt-30 pb-30 white-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact-from mt-30">
                            <div className="section-title">
                                <h5>Contactez-Nous</h5>
                            </div>
                            <div className="main-form pt-45">
                                <form id="contact-form" action="contact.php" method="post" data-toggle="validator">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="name" type="text" placeholder="Votre nom"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="email" type="email" placeholder="Email"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="subject" type="text" placeholder="Sujet"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input name="phone" type="text" placeholder="Telephone"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="single-form form-group">
                                                <textarea name="message" placeholder="Message"
                                                    data-error="Ceci est un champ obligatoire."
                                                    required="required"></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <p class="form-message"></p>
                                        <div class="col-md-12">
                                            <div class="single-form">
                                                <button type="submit" class="main-btn">Envoyer <i class="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="contact-address mt-30">
                            {contacts.map(c => (

                                <div class="single-address mb-30 border-bottom pb-15" key={c.id}>
                                    <div class="cont  w-100">
                                        <h6 class="text-uppercase mb-10"> {c.name}</h6>
                                        <p> <i class="fa fa-phone mr-10"></i> {c.phone}</p>
                                        <p> <i class="fa fa-envelope mr-10"></i>{c.email}</p>
                                        <p> <i class="fa fa-link mr-10"></i>{c.website}</p>
                                    </div>
                                </div>


                            ))}

                        </div>
                        <div class="map mt-30">
                            <div id="contact-map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}
