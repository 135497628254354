import React from 'react';

import BannerSlider from '../components/Home/BannerSlider';
import DirectorWords from '../components/Home/DirectorWords';
import Newsletter from "../components/Footer/Newsletter";
import Actualites from "../components/Home/Actualites";
import Projets from "../components/Home/Projets";

export default function Home() {
    
    return (
        <>
            <BannerSlider />
            <DirectorWords />
            <Projets />
            <Actualites />
            <Newsletter />
        </>
    )
}