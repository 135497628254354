import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import _ from 'lodash'
import OtherModuleMenu from './OtherModuleMenu';
import { Link } from 'react-router-dom';

const PUBLIC_URL = process.env.PUBLIC_URL

const Navigation = () => {

    const {appState, setAppState} = useContext(AppContext)
    let {collapsed, defaultdatas:{menus}} = appState
    const [modalFilter, setModalFilter] = useState(false)

    const generateLink = menu =>{
       if(menu.page_id) return `/page/${menu.pages.permalink}`
       else if(menu.module_id) return `/section/${menu.modules.name}`
       else if(menu.target_link) {

        if( menu.target_link.indexOf("https") == 0 ){
            console.log(menu.target_link);
             return `/frame/${encodeURIComponent(menu.target_link)}`
        }
        if(menu.target_link.indexOf("http") == 0 ){
            return PUBLIC_URL + menu.target_link
        }

        return menu.target_link
       }
       else if(menu.display_services_pages) return `/services`
       else return '/'
    }

    const formatChildren = (menus) => {
        const menusMap = menus.filter(m=>m.parent_id ==null)
        menusMap.map((mp,indexp)=>{
            menusMap[indexp]["children"] = []
            menus.map(mn=>{
                if(mn.parent_id==mp.id) 
                menusMap[indexp].children.push(mn)
            })
        })

        return menusMap
    }

    const toggleModalFilter = () => {
        setModalFilter(!modalFilter)
    }

    menus = formatChildren(menus.data);  
    let menus_1 = menus.slice(0,7) 
    let menus_2 = menus.slice(7) 
    return ( 
        <header id="header-part">
        {<div className="navigation">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand" to="/">
                                <img src={`${PUBLIC_URL}/assets/images/logo-dpp.png`} alt="Logo" className="img-fluid site-logo"/>
                            </Link>
                            <button onClick={()=>{setAppState({...appState, collapsed:collapsed=="on"?"off":"on"})}}  className={`navbar-toggler ${collapsed=="on"?'collapsed':'active'}`} type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded={collapsed=="on"?"false":"true"} aria-label="Toggle navigation">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>

                             <div className={`collapse navbar-collapse sub-menu-bar collapse ${collapsed=="on"?'':'show'}`} id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    {menus_1 && menus_1.map((menu) => (
                                        <li className="nav-item" key={`parentmenu${menu.id}`}>
                                            <Link className={menu.id == 1 ? 'active' : ""} to={generateLink(menu)} 
                                              >{menu.title}</Link>
                                            <ul className="sub-menu">
                                                {menu.children.map((childrens) => (
                                                    <li key={`submenu${childrens.id}`}><a href={generateLink(childrens)} >{childrens.title}</a></li>
                                                 ))
                                                }
                                               
                                            </ul>
                                        </li>
                                      ))}
                                      <li className="nav-item"><a className="cursor" onClick={()=>toggleModalFilter()} 
                                              ><i className="fa fa-ellipsis-v"></i></a></li>
                                </ul>
                            </div> 
                            <div className="right-icon text-right ">
                                <ul>
                                    <li><span onClick={()=>{setAppState({...appState, searchBarDisplay:true})}} id="search"><i className="fa fa-search"></i></span></li>
                                </ul>
                            </div> 
                        </nav> 
                    </div>
                    <div>

                <div id="mySidenav" className={`sidenav section-title ${modalFilter && 'active'} `}>
                    <i className="closebtn text-gray fa fa-close" onClick={() => toggleModalFilter()}></i>
                    <div className="row">
                    {menus_2 && menus_2.map((menu) => (
                        <div className="col-md-6 mt-20 mb-10">
                                        <button  className="nav-item text-uppercase text-bold btn btn-success  btn-block nocursor" key={`parentmenu${menu.id}`}>
                                    {menu.title}</button >
                                    <ul className="mt-20">
                                                {menu.children.map((childrens) => (
                                                    <li className="pl-20" key={`submenu${childrens.id}`}><a href={generateLink(childrens)} >{childrens.title}</a></li>
                                                 ))
                                                }
                                               
                                            </ul>
                         </div>           
                           
                                      ))}                        
                    </div>

                </div>

                    </div>
                </div> 
            </div> 
        </div>}        
        <OtherModuleMenu />
    </header>     );
}

export default Navigation;