import React, { useContext, useEffect, useState  } from 'react';
import { AppContext } from '../../App';
import ContentLoader from 'react-content-loader'
import { getResource } from '../../service/api'

const ProjetsLoader = () => (
    <ContentLoader viewBox="0 0 380 60">
        <rect x="15" y="0" rx="5" ry="7" width="120" height="100" />
        <rect x="140" y="0" rx="5" ry="7" width="120" height="100" />
        <rect x="265" y="0" rx="5" ry="7" width="120" height="100" />
    </ContentLoader>
)
const Projets = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const [projets, setProjets] = useState(null)

    useEffect(() => {
        getResource(`projets?&public=1`).then(res => {
            setProjets(res.data)
        })
    }, [])

    return (
        <div>
            <section id="course-part" className="pt-50 pb-50  animated slideInLeft">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title pb-45">
                                <h5>Projets</h5>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                    {!projets && <ProjetsLoader />}
                        {projets && projets.data.map(data => (
                        <div key={data.id} className="col-sm-12 col-md-6 pt-10">
                            <div className="card">
                                <div className="card-header ">
                                    <h5 className="card-title text-white ">{data.nom}</h5>
                                </div>
                                <div className="card-body projet-body">
                                    <p className="card-text">{data.description&&data.description.slice(0,100)+"..."}
                                    </p>
                                    <a href={`/projets/${data.id}`} className="btn btn-lg btn-success  projet-action"
                                        title="Lire plus"> <i className="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        ))
                        }
                        {projets && typeof projets.total !== 'undefined' && projets.total > 0 ?
                            <div className="single-event mx-auto">
                                <a href="/projets" className="w-100 main-btn mt-20 text-white">
                                    Voir Plus
                                </a>
                            </div>
                        : ""}
                    </div> 
                </div>
            </section>
        </div>
    )
}
export default Projets
