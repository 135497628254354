import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentProjets from '../components/Projets/ContentProjets';
import {getResource} from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';

export default function Projets() {

    const {slug} = useParams();
    const[currentprojets, setCurrentProjets] = useState(null);

    useEffect(() => {
        getResource(`projets?id=${slug}&public=1`).then(res => {
            if(res.data.data.length>0){
                setCurrentProjets(res.data.data[0]);
            }    
        })
    }, []);

    return (
        <>
        {!currentprojets && <Loader/>}
            {currentprojets &&<Ariane title={currentprojets.nom}/>}
            {currentprojets &&<ContentProjets projets={currentprojets}/>}
        </>
    )
}
