import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentIframe from '../components/Iframe/ContentIframe';
import {getResource} from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';

export default function Iframe() {

    const {slug} = useParams();

    return (
        <>
        {!slug && <Loader/>}
            {/* {currentpage &&<Ariane title={currentpage.title}/>} */}
            {slug &&<ContentIframe url={slug}/>}
        </>
    )
}
