import React, { useEffect, useState } from 'react';
import { getResource } from '../../service/api';
import ContentLoader from 'react-content-loader'
import $ from "jquery";

import moment from 'moment'
import { Alert } from 'react-bootstrap';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 150">
        <rect x="0" y="20" rx="4" ry="4" width="100%" height="18" />
        <rect x="0" y="45" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="70" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="95" rx="3" ry="3" width="100%" height="18" />
    </ContentLoader>
)

const ContentModuleDocumentation = ({ moduleObj }) => {
    const [module, setModule] = useState(null)
    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadDocs()
    }, [])

    // $(function() {
    //     $('#datepicker').datepicker({
    //       changeYear: true,
    //       showButtonPanel: true,
    //       dateFormat: 'yy',
    //       onClose: function(dateText, inst) {
    //         var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
    //         $(this).datepicker('setDate', new Date(year, 1));
    //       }
    //     });
    //     $(".date-picker-year").focus(function() {
    //       $(".ui-datepicker-month").hide();
    //     });
    //   });
      
    const loadDocs = () => {
        getResource(`documentations?module_id=${moduleObj.id}&page=${currentPage}&public=1`).then(res => {
            setDocuments(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setLoading(false)

        })
    }
    useEffect(() => {
        setLoading(true)
        loadDocs()
    }, [currentPage])

    return (

        <>
            {/* <div >
                <h4 className=" criteria mb-3 ">Rechercher un document</h4>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <input type="text" class="form-control" id="datepicker" placeholder="Password"/>
                    </div>
                    <div class="form-group col-md-4">
                        <input type="email" class="form-control" id="inputEmail4" placeholder="Mots clés"/>
                    </div>
                    <div class="w-100 col-md-3">
                        <button className='btn btn-primary' type="submit"><i className="fa fa-search m-1"></i>RECHERCHER</button>
                    </div>
                    
                </div>

            </div> */}
            <div class="list-group p-3 section-title">
                {!loading && documents && <h5 className="mb-20">Document(s) téléchargeable(s)</h5>}
                {typeof documents !== 'undefined' && documents.length > 0 ? documents.map(c => (
                    <a href={`${directory_doc}/${c.fichier.filename}`} key={c.id} class={`list-group-item list-group-item-action cursor p-3`} aria-current="true">
                        {c.title} <i className="fa fa-download pull-right"></i></a>
                )) : <Alert className="col-md-6" variant="secondary"><h3>Pas de document disponible</h3></Alert>}

                {loading && <MyLoader />}
            </div>
        </>
    );
}

export default ContentModuleDocumentation;