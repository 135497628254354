import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import moment from 'moment'
import ContentModuleDocumentation from './ContentModuleDocumentation';
import ContentModuleTableGraphe from './ContentModuleTableGraphe';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;


export default function ContentModuleDocument({ module }) {


    return (
        <div id="simple-page" className="pt-90 pb-120 bg-white">
            <div className="container">
            <ContentModuleDocumentation moduleObj={module}/>
            <ContentModuleTableGraphe moduleObj={module}/>
            </div>
        </div>
    )
}
