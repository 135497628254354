import React from 'react';
import Links from "../components/Footer/Links";

const PUBLIC_URL = process.env.PUBLIC_URL

export default function Footer() {
    return (
        <>
        <footer className="site-footer">
        <div className="container">
            <div className="row pb-5 pt-3">
                <div className="col-md-3 pt-5 mt-2">
                    <img src={`${PUBLIC_URL}/assets/images/logo-dpp.png`} alt=" white-img footer logo" className="logoF d-inline pb-3 footer-logo"/>
                </div>
                <div className="col-md-7">

                <Links/>
                
                    <div>
                        <p className="text-right text-white">
                            © Ministère de l'Agriculture, de l'Elevage et de la Peche - 2021
                        </p>
                    </div>
                </div>
                <div className="col-md-2 pt-5 mt-2">
                    <img src={`${PUBLIC_URL}/assets/images/europeanunion.jpg`} alt="footer logo" className="d-inline left pb-3 footer-logo pull-right"/>
                </div>
                <small class="text-white col-lg-12 mt-3 ml-1 mr-1 mb-0 text-center">Ce site a été créé et maintenu avec le soutien financier de l’Union européenne.
                 Son contenu relève de la seule responsabilité du DPP sous la supervision de la DSI/MAEP et n’engage par la responsabilité de l’Union européenne.</small>

            </div>
        </div>
        <div className="flag-look-like">
            <div className="col green-block"></div>
            <div className="col yellow-block"></div>
            <div className="col red-block"></div>
        </div>
    </footer>
        </>
    )
}
